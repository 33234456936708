<template>
  <div class="publicity">
    <page-nav></page-nav>
  </div>

</template>

<script>
import PageNav from "../components/pageNav";
export default {
  name: "publicity",
  components: {PageNav},
  data() {
    return {

    }
  }
}
</script>

<style scoped lang="scss">
.publicity {

}
</style>
