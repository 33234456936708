<template>
  <div class="nav-list" :class="scrollFlag?'navActive':''">
    <div class="container">
      <div class="page-nav" >
        <div class="page-nav-item" v-for="item in pageList" :key="item.name">
          <template v-if="item.id === '/information'">
            <a @click="checkActive(item.id,$event)">{{item.name}}</a>
          </template>
          <template v-else>
            <a :href="'#'+item.id" :class="activeId == item.id? 'selected':''" @click="checkActive(item.id,$event)">{{item.name}}</a>
            <div class="children-box isShow" v-if="item.children && item.children.length > 0">
              <div class="sm-img" v-for="it in item.children" :key="it.title">
                <img :src="it.img" />
                <div class="title">{{it.title}}</div>
                <div>{{it.desc}}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageNav",
  data(){
    return {
      activeId: 'content_img1',
      scrollFlag: false,
      pageList:[
        {name: '公司首页', page:'/index', id:'content_img1'},
        {name: '公司介绍', page: '/',id:'content_box1'},
        {name: '公司产品', page: '',id:'content_product_box'},
        { name: '用户案例', page: '', id: 'content_img2' },
        {name: '企业资讯', page: '/information',id:'/information'},
        { name: '联系方式', page: '', id: 'content_connect_box' },
      ],
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, false);
  },
  methods: {
    checkActive(id, e) {
      if (id === '/information') {
        this.$router.push({path: id})
      } else { 
        e = e || event
        e.preventDefault()
        this.activeId = id
        let targetEle = document.getElementById(id)
        let h = targetEle.offsetTop
        window.scrollTo(0,h-100)
      }
    },

    handleScroll() {
      const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      if (scrollTop) {
        this.scrollFlag = true;
      } else {
        this.scrollFlag = false;
      }

      this.pageList.map(item => {
        if (item.id === '/information') { 
          return item
        }
        let targetEle = document.getElementById(item.id)
        let h = targetEle.offsetTop
        // console.log(h,scrollTop)
        if(h <= (scrollTop+120)) {
          this.activeId = item.id
        }else if(h == (scrollTop + 271)) {
          this.activeId = 'content_connect_box'
        }else {
          return
        }
      })
    },
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, false);
  },
}
</script>

<style scoped lang="scss">
.navActive {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255,255,255,.5) !important;
}
.nav-list {
  position: fixed;
  width: 100%;
  height: 68px;
  line-height: 68px;
  z-index: 999;
  background-color: #ffffff;
}
.page-nav {
  display: flex;
  justify-content: space-between;
  .page-nav-item {
    color: #ADB6CA;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    a {
      color: $colorL1;
      &.selected {
        color: $colorA;
      }
    }
    &:hover {
      a {
        color: $colorA;
      }
      .isShow {
        display: block;
        z-index:99;
      }
    }

    .children-box {
      width: 420px;
      position: absolute;
      right: 0;
      background-color: #ffffff;
      padding: 20px;
      display: none;
      .sm-img {
        display: inline-block;
        font-size: 12px;
        width: 120px;
        margin: 0 10px;
        line-height: 20px;
        .title {
          font-weight: 600;
          margin: 8px 0px;
        }
      }
    }
  }
}
</style>
